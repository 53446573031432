<template>
    <div class="approve">
        <van-search v-model="searchData" placeholder="请输入搜索关键词" />
        <div>
            <pendingApprove v-show="active == 0"/>
            <myApprove v-show="active == 2" />
        </div>
        <van-tabbar v-model="active" @change="onChange">
            <van-tabbar-item icon="todo-list-o">待处理</van-tabbar-item>
            <van-tabbar-item icon="add-o">添加</van-tabbar-item>
            <van-tabbar-item icon="contact">我的</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    import myApprove from './myApprove'
    import pendingApprove from './pendingApprove'
    import { Search, Tabbar, TabbarItem } from 'vant';
    export default {
        name:'approve',
        components: {
            [Search.name]: Search,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            myApprove,
            pendingApprove,
        },
        data() {
            return {
                searchData: '',
                active: 0
            }
        },
        methods: {
            onChange(e) {
                if(e == 1){
                    this.$router.push({path:"/oa/approve/add"})
                }
            }
        },
    }
</script>

<style lang="less" scoped>
    .approve{
        min-height: 100vh;
        background: #fff;
    }
</style>