<template>
    <div>
        <van-tabs v-model="active">
            <van-tab title="我提交"><mySubmit/></van-tab>
            <van-tab title="我审批"><mineApprove/></van-tab>
            <van-tab title="我相关"><myRelevant/></van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import myRelevant from './myRelevant'
    import mineApprove from './mineApprove'
    import mySubmit from './mySubmit'
    import { Tab, Tabs } from 'vant'
    export default {
        name:'myApprove',
        components: {
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            mySubmit,
            myRelevant,
            mineApprove
        },
        data() {
            return {
                active: 0
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>