<template>
    <div class="myRelevant">
        <div class="myRelevant-list">
            <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="listFinishedText"
                @load="onLoad"
                >
                <van-cell v-for="(item,index) in list" :key="index" >
                    <template #title>
                        <div class="myRelevant-list-info">
                            <div>
                                <avatar src="http://i01.wedots.cn/2/2018/5/3BQ2Iovw3DA.jpeg?imageMogr2/thumbnail/180x180!/quality/75/format/png" color="#fff" :size="36"></avatar>
                            </div>
                            <div>
                                <div>
                                    <span>{{item.created_by}}</span>
                                    <span>_{{dayjs(item.created_at).format('YYYYMMDD')}}</span>
                                    <span v-show="item.flow_id == it.value" v-for="it in approveContent" :key="it.vuale">_{{it.label}}</span>
                                </div>
                                <div>
                                    <van-tag style="margin-right: 5px" type="success">{{item.check_status_name}}</van-tag>
                                    <van-tag round color="#f4f4f4">{{item.created_by}}</van-tag>
                                    <van-tag round color="#f4f4f4">{{dayjs(item.created_at).format('MM-DD')}}</van-tag>
                                </div>
                            </div>
                        </div>
                    </template>
                </van-cell>
            </van-list>
            <van-empty v-if="list.length == 0" description="暂无相关数据~" />
        </div>
        <!-- <loadings :loading='loading' /> -->
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import { Empty, Tag} from 'vant'
    import Avatar from 'vue-avatar'
    import Loadings from '@/components/loading'
    export default {
        name:'myRelevant',
        data() {
            return {
                loading: false,
                finished: false,
                list: [],
                listFinishedText: '没有更多了',
                approveContent:[
                    {label:'普通审批',value:1},
                    {label:'请假审批',value:2},
                    {label:'出差审批',value:3},
                    {label:'加班审批',value:4},
                    {label:'差旅报销',value:5},
                    {label:'借款申请',value:6},
                    {label:'合同退费审批流程',value:100},
                ]
            }
        },
        components: {
            [Empty.name]: Empty,
            [Tag.name]: Tag,
            [Avatar.name]: Avatar,
            loadings:Loadings,
        },
        created () {
            this.getList()
        },
        methods: {
            dayjs,
            getList(){
                this.loading = true
                this.$api.oa_examine()
                .then(res=>{
                    this.list = res.items
                    this.loading = false
                })
            },
            onLoad() {
                
            }
        },
    }
</script>

<style lang="less">
.myRelevant{
    &-list{
        &-info{
            display: flex;
            align-items: center;
            position: relative;
            .vue-avatar--wrapper{
                margin-right: 10px;
            }
        }
        .van-cell{
            align-items: center;
            background-color: rgba(0,0,0,0);
        }
        .van-tag--default{
            margin-right: 5px;
            color: #888;
            font-size: 10px;
        }
    }
}
</style>