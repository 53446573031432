<template>
    <div class="mySubmit">
        <div class="mySubmit-list">
            <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="listFinishedText"
                @load="onLoad"
                >
                <van-cell v-for="(item,index) in list" is-link :key="index" >
                    <template #title>
                        <div class="mySubmit-list-info">
                            <div>
                                <div>
                                    <span>{{item.created_by}}</span>
                                    <span>_{{dayjs(item.created_at).format('YYYYMMDD')}}</span>
                                    <span v-show="item.flow_id == it.value" v-for="it in approveContent" :key="it.vuale">_{{it.label}}</span>
                                </div>
                                <div class="mySubmit-list-content">
                                    <van-tag type="success">{{item.check_status_name}}</van-tag>
                                    <div class="van-ellipsis mySubmit-list-text">{{item.content}}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #right-icon>
                        <van-icon @click.stop="showSheet(item)" style="transform: rotate(90deg);" name="ellipsis" />
                    </template>
                </van-cell>
            </van-list>
            <van-empty v-if="list.length == 0" description="暂无相关数据~" />
        </div>
        <van-action-sheet
            v-model="showDelete"
            :actions="actions"
            cancel-text="取消"
            description="是否删除该申请单"
            close-on-click-action
            @select="handleSelect"
        />
        <!-- <loadings :loading='loading' /> -->
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import { Empty, Tag, ActionSheet} from 'vant'
    import Loadings from '@/components/loading'
    export default {
        name:'mySubmit',
        data() {
            return {
                loading: false,
                finished: false,
                list: [],
                listFinishedText: '没有更多了',
                showDelete:false,
                actions:[{ name: '删除' }],
                approveContent:[
                    {label:'普通审批',value:1},
                    {label:'请假审批',value:2},
                    {label:'出差审批',value:3},
                    {label:'加班审批',value:4},
                    {label:'差旅报销',value:5},
                    {label:'借款申请',value:6},
                    {label:'合同退费审批流程',value:100},
                ]
            }
        },
        components: {
            [Empty.name]: Empty,
            [Tag.name]: Tag,
            [ActionSheet.name]: ActionSheet,
            loadings:Loadings,
        },
        created () {
            this.getList()
        },
        methods: {
            dayjs,
            getList(){
                this.loading = true
                this.$api.oa_examine()
                .then(res=>{
                    this.list = res.items
                    this.loading = false
                })
            },
            onLoad() {
                
            },
            showSheet(item){
                this.showDelete = true
            },
            handleSelect(){
                console.log('slslslsl')
            }
        },
    }
</script>

<style lang="less">
.mySubmit{
    &-list{
        &-info{
            display: flex;
            align-items: center;
            position: relative;
        }
        &-content{
            display: flex;
            align-items: center;
        }
        &-text{
            width: 250px;
            margin-left: 10px;
        }
        .van-cell{
            align-items: center;
            background-color: rgba(0,0,0,0);
        }
    }
}
</style>